export class UICarousel extends HTMLElement {
  static SLIDE_THRESHOLD = 0.35;
  static observedAttributes = ["name", "locked"];

  private locked: boolean = false;

  private slides: HTMLElement[] = [];
  private activeSlide: number = 0;

  private x: number = 0;
  private isSwipe: boolean = false;
  private isScroll: boolean = false;
  private transition: boolean = true;
  private allowSwipe: boolean = true;

  private swipeStartTime: number = 0;
  private swipeEndTime: number = 0;

  private touchStartXRef: number = 0;
  private touchX1Ref: number = 0;
  private touchX2Ref: number = 0;

  private touchY1Ref: number = 0;
  private touchY2Ref: number = 0;

  private imageWidth: number = 0;
  private lastImage: number = 0;
  private nextImage: number = 0;
  private prevImage: number = 0;

  private slidesWrapper: HTMLElement | null = null;

  constructor() {
    super();
    this.onResize = this.onResize.bind(this);
  }

  connectedCallback() {
    const slides = this.querySelectorAll<HTMLElement>("ui-carousel-slide");

    // Wrap slides in a container
    const wrapper = document.createElement("ui-carousel-slides");
    slides.forEach((slide) => {
      wrapper.append(slide);
    });
    this.slides = Array.from(slides);
    this.append(wrapper);
    this.slidesWrapper = wrapper;
    this.imageWidth = this.slidesWrapper.children[0].clientWidth ?? 0;
    this.setX(0);
    this.setAttribute("slide", this.activeSlide.toString());

    if (this.locked) return;

    this.setListeners();
  }

  private setListeners = () => {
    this.addEventListener("touchstart", this.onTouchStart, { passive: true });
    this.addEventListener("touchmove", this.onTouchMove, { passive: true });
    this.addEventListener("touchend", this.onTouchEnd, { passive: true });
    window.addEventListener("resize", this.onResize);
    window.addEventListener("htmx:afterSettle", this.onResize);
  };

  private removeListeners = () => {
    this.removeEventListener("touchstart", this.onTouchStart);
    this.removeEventListener("touchmove", this.onTouchMove);
    this.removeEventListener("touchend", this.onTouchEnd);
    window.removeEventListener("resize", this.onResize);
    window.removeEventListener("htmx:afterSettle", this.onResize);
  };

  disconnectedCallback() {
    // console.log("Carousel removed from page.");
    this.removeListeners();
  }

  adoptedCallback() {
    // Noop
    // console.log("Carousel moved to new page.");
  }

  attributeChangedCallback(name: string, oldValue: unknown, newValue: unknown) {
    // Noop
    // console.log(`Attribute ${name} has changed. Old value: ${oldValue}. New value: ${newValue}`);
    if (oldValue === newValue) return;

    if (typeof this[name] === "boolean") {
      this[name] = newValue === "true";
    } else if (typeof this[name] === "number") {
      this[name] = parseInt(newValue as string);
    } else {
      this[name] = newValue;
    }

    if (name === "locked") {
      if (this.locked) {
        this.removeListeners();
      } else {
        this.setListeners();
      }
    }
  }

  public setSlide(slide: number) {
    if (slide < 0 || slide >= this.slides.length) {
      console.warn(`Expected slide index to be between 0 and ${this.slides.length - 1} but got ${slide}`);
      return;
    }
    this.activeSlide = slide;
    this.slide();
  }

  public nextSlide() {
    const nextSlideIndex = this.activeSlide + 1;
    if (nextSlideIndex >= this.slides.length) {
      this.activeSlide = 0;
    } else {
      this.activeSlide = nextSlideIndex;
    }
    this.slide();
  }

  public prevSlide() {
    const prevSlideIndex = this.activeSlide - 1;
    if (prevSlideIndex < 0) {
      this.activeSlide = this.slides.length - 1;
    } else {
      this.activeSlide = prevSlideIndex;
    }
    this.slide();
  }

  private setX(x: number) {
    this.x = x;
    if (this.slidesWrapper === null) return;
    this.slidesWrapper.style.transform = `translateX(${this.x}px)`;
    this.slidesWrapper.style.transition = this.transition ? "transform 0.5s ease" : "none";
  }

  private slide() {
    this.setX(-this.activeSlide * this.imageWidth);
    this.setAttribute("slide", this.activeSlide.toString());
  }

  private onTouchStart(e: TouchEvent) {
    if (!this.allowSwipe) return;

    const evt = e.touches[0];

    this.swipeStartTime = Date.now();
    this.transition = false;

    this.imageWidth = this.slidesWrapper?.children[0].clientWidth ?? 0;
    if (!this.imageWidth) return;
    this.nextImage = (this.activeSlide + 1) * -this.imageWidth;
    this.prevImage = (this.activeSlide - 1) * -this.imageWidth;
    this.lastImage = (this.slides.length - 1) * this.imageWidth;

    this.touchStartXRef = evt.clientX;
    this.touchX1Ref = evt.clientX;
    this.touchY1Ref = evt.clientY;
  }

  private onTouchMove(e: TouchEvent) {
    const evt = e.touches[0];

    this.touchX2Ref = this.touchX1Ref - evt.clientX;
    this.touchX1Ref = evt.clientX;

    this.touchY2Ref = this.touchY1Ref - evt.clientY;
    this.touchY1Ref = evt.clientY;

    if (!this.isSwipe && !this.isScroll) {
      let posY = Math.abs(this.touchY2Ref);
      if (posY > 7 || this.touchX2Ref === 0) {
        this.isScroll = true;
        this.allowSwipe = false;
      } else if (posY < 7) {
        this.isSwipe = true;
      }
    }

    if (this.isSwipe) {
      // First slide, keep from sliding to left
      if (this.activeSlide === 0) {
        if (this.touchStartXRef < this.touchX1Ref) {
          if (this.x >= 0) {
            this.setX(0);
          }
          this.allowSwipe = false;
          return;
        } else {
          this.allowSwipe = true;
        }
      }

      // Last slide
      if (this.activeSlide === this.slides.length - 1) {
        if (this.touchStartXRef > this.touchX1Ref) {
          if (this.x >= this.lastImage) {
            this.setX(this.lastImage);
          }
          this.allowSwipe = false;
          return;
        } else {
          this.allowSwipe = true;
        }
      }

      // Touch on edge
      if (
        (this.touchStartXRef > this.touchX1Ref && this.x < this.nextImage) ||
        (this.touchStartXRef < this.touchX1Ref && this.x > this.prevImage)
      ) {
        // this.transition = false;
        this.onTouchEnd(e); // Reached edge
        this.allowSwipe = false;
        return;
      }

      this.setX(this.x - this.touchX2Ref);
    }
  }

  private onTouchEnd(_e: TouchEvent) {
    const touchThreshold = this.imageWidth * UICarousel.SLIDE_THRESHOLD;
    const touchFinalX = this.touchStartXRef - this.touchX1Ref;
    this.isScroll = false;
    this.isSwipe = false;

    if (this.allowSwipe) {
      this.swipeEndTime = Date.now();
      this.transition = true;

      if (Math.abs(touchFinalX) > touchThreshold || this.swipeEndTime - this.swipeStartTime < 300) {
        if (this.touchStartXRef < this.touchX1Ref) {
          this.activeSlide--;
          this.setAttribute("slide", this.activeSlide.toString());
        } else if (this.touchStartXRef > this.touchX1Ref) {
          this.activeSlide++;
          this.setAttribute("slide", this.activeSlide.toString());
        }
        this.setX(-this.activeSlide * this.imageWidth);
      }

      if (this.touchStartXRef !== this.touchX1Ref) {
        this.setX(-this.activeSlide * this.imageWidth);
      } else {
        this.allowSwipe = true;
      }
    } else {
      this.allowSwipe = true;
    }
  }

  private onResize() {
    this.imageWidth = this.slidesWrapper?.children[0].clientWidth ?? 0;
    this.setX(-this.activeSlide * this.imageWidth);
  }
}

export const registerUICarousel = () => {
  customElements.define("ui-carousel", UICarousel);
};
