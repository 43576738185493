export const initUICollapse = (target?: HTMLElement) => {
  const element = target ?? document;
  const triggers = element.querySelectorAll(`[data-collapse-target]`);
  triggers.forEach((trigger) => {
    const target = (trigger as HTMLElement).dataset.collapseTarget;
    if (!target) return;

    const collapsable = document.querySelector<HTMLElement>(`[data-collapse='${target}']`);
    if (!collapsable) {
      console.warn(`Expected to find collapsable element with attribute 'data-collapse="${target}"' but found none.`);
    }

    trigger.addEventListener("click", () => {
      const c = collapsable as HTMLElement;
      if (!c.dataset) return;
      c.dataset.collapseOpen = c.dataset.collapseOpen === "true" ? "false" : "true";
      if (c.dataset.collapseOpen === "false") {
        c.style.maxHeight = "0";
        c.style.overflow = "hidden";
      } else {
        c.style.maxHeight = c.scrollHeight + "px";
      }

      collapsable?.addEventListener("transitionend", () => {
        const isOpen = c.dataset.collapseOpen === "true";
        if (isOpen) {
          c.style.overflow = "visible";
        }
      });
    });
  });
};
