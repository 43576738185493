export abstract class UIBaseComponent extends HTMLElement {
  static observedAttributes: string[] = [];

  name: string;

  constructor(name: string) {
    super();
    if (!/^ui-/.test(name)) {
      throw new Error(`UI Component name should be prefixed 'ui-' but got ${name}`);
    }
    this.name = name;
  }

  connectedCallback() {}

  disconnectedCallback() {
    // console.log("Carousel trigger removed from page.");
  }

  adoptedCallback() {
    // console.log("Carousel trigger moved to new page.");
  }

  attributeChangedCallback(name: string, oldValue: unknown, newValue: unknown) {
    // console.log(`Attribute ${name} has changed - ${oldValue} -> ${newValue}`);

    if (oldValue === newValue) return;
    // @ts-ignore
    this[name] = newValue;
  }

  static register() {
    throw new Error("Method not implemented.");
  }
}
