// import { createRoot } from "react-dom/client";
import "./assets/styles/index.scss";
import "vite/modulepreload-polyfill";
/**
 * "To best leverage Stripe’s advanced fraud functionality, ensure that Stripe.js is loaded on every page, not just your checkout page.
 * This allows Stripe to detect suspicious behavior that may be indicative of fraud as customers browse your website."
 * @ https://github.com/stripe/stripe-js
 */
import "@stripe/stripe-js";
import { initNavigation } from "./components/header";
import { initUIComponents, registerUIWebComponents } from "./components";
import toasts, { initToasts } from "./components/toasts";
import { dispatchPushStateEvent, onDOMReady } from "./utils";
import { saveAs } from 'file-saver';

const setListeners = () => {
  document.addEventListener('scroll', () => {
    const scrollY = window.scrollY;
    if (scrollY > 0) {
      document.body.classList.add('scrolled');
    } else {
      document.body.classList.remove('scrolled');
    }
  });


  document.addEventListener('htmx:downloadReport', (e) => {
    const url = (e as unknown as { detail: {value: string}}).detail.value;

    const { addToast, removeToast } = toasts();
    const toastId = addToast({type: 'INFO', title: 'Preparing report..', loading: true })

    fetch(url)
      .then(res => {
        const contentDisposition = res.headers.get('Content-Disposition') ?? "";
        const fileName = /filename\*?=([^']*'')?([^;]*)/.exec(contentDisposition)?.[2] ?? "file";

        res.blob().then(blob => {
          if (res.ok) {
            saveAs(blob, fileName);
          }

          removeToast(toastId);

          const triggers = JSON.parse(res.headers.get('HX-Trigger') || '{}');
          const toast = triggers['addToast'];

          if (toast) {
            addToast(toast)
          }
        })
    });
  });
}

const init = () => {
  initNavigation();
  initUIComponents();
  initToasts();
  registerUIWebComponents();

  setListeners();

  dispatchPushStateEvent();
};

onDOMReady(init)

// Re-initialize components after HTMX swaps
document.addEventListener("htmx:afterSwap", (e) => {
  const target = e.target as HTMLElement;
  initUIComponents(target);
});

/*
function ExampleComponent() {
    return <h1 className={"text-green-500"}>Hello from React!</h1>;
}

const domNode = document.getElementById("react-test");
if (!domNode) throw new Error('Expected to find <div id="react-test"> but found none.');

const root = createRoot(domNode);
root.render(<ExampleComponent />);
*/
