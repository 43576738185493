export const initUIModals = (target?: HTMLElement) => {
  const element = target ?? document;
  const triggers = element.querySelectorAll<HTMLElement>(`[data-modal-trigger]`);
  triggers.forEach((trigger) => {
    const target = (trigger as HTMLElement).dataset.modalTrigger;
    if (!target) return;

    const modal = document.querySelector<HTMLElement>(`[data-modal='${target}']`);
    if (!modal) {
      if (target === "all") return;
      console.warn(`Expected to find a modal with an attribute 'data-modal="${target}"' but found none.`);
      return;
    }

    if (modal.dataset.modalControl === "manual") return;

    trigger.addEventListener("click", () => {
      if (target === "all") {
        const allModals = document.querySelectorAll<HTMLElement>(`[data-modal][data-open="true"]`);
        allModals.forEach((modal) => {
          modal.dataset.open = "false";
        });
        return;
      }

      modal!.dataset.open = modal!.dataset.open === "true" ? "false" : "true";
    });
  });
};
