import { UICarousel } from "./ui-carousel";

export class UICarouselTrigger extends HTMLElement {
  static observedAttributes = ["carousel", "slide"];
  carouselElement: UICarousel | null = null;
  carousel: string;
  slide: string | "next" | "prev";
  observer: MutationObserver | null = null;

  constructor() {
    super();
    this.carousel = "n/a";
    this.slide = "0";
  }

  onClick = (e: MouseEvent) => {
    e.preventDefault();
    if (!this.carouselElement) return;

    if (this.slide === "next") {
      this.carouselElement.nextSlide();
    } else if (this.slide === "prev") {
      this.carouselElement.prevSlide();
    } else {
      const slide = parseInt(this.slide);
      this.carouselElement.setSlide(slide);
    }
  };

  connectedCallback() {
    if (!this.slide || (isNaN(parseInt(this.slide)) && !["next", "prev"].includes(this.slide))) {
      console.warn(`Expected 'slide' attribute to be of type any positive number, "next" or "prev" but got ${this.slide}`);
      return;
    }

    this.carouselElement = document.querySelector<UICarousel>(`ui-carousel[name="${this.carousel}"]`);

    if (!this.carouselElement) {
      console.warn(`Expected to find ui carousel with name '${this.carousel}' but instead found ${typeof this.carouselElement}`);
      return;
    }

    this.observer = new MutationObserver((mutations) => {
      const slideAttributeRecord = mutations.find((m) => m.attributeName === "slide");
      if (!slideAttributeRecord) return;

      if (isNaN(parseInt(this.slide))) return;
      if (this.carouselElement?.getAttribute("slide") === this.slide) {
        this.classList.add("active");
      } else {
        this.classList.remove("active");
      }
    });

    this.observer.observe(this.carouselElement, {
      attributes: true,
    });

    if (this.carouselElement.getAttribute("slide") === this.slide) {
      this.classList.add("active");
    } else {
      this.classList.remove("active");
    }

    this.addEventListener("click", this.onClick);
  }

  disconnectedCallback() {
    // console.log("Carousel trigger removed from page.");
    this.observer?.disconnect();
  }

  adoptedCallback() {
    // console.log("Carousel trigger moved to new page.");
  }

  attributeChangedCallback(name: string, oldValue: unknown, newValue: unknown) {
    // console.log(`Attribute ${name} has changed.`);

    if (oldValue === newValue) return;
    // @ts-ignore
    this[name] = newValue;
  }
}

export const registerUICarouselTrigger = () => {
  customElements.define("ui-carousel-trigger", UICarouselTrigger);
};
