import { UIBaseComponent } from "./ui-base-component";
import { computePosition, flip, offset, Placement, shift } from "@floating-ui/dom";

// TODO: Base class for floating UI components
export class UIConfirmButton extends UIBaseComponent {
  static observedAttributes = ["placement"];
  hover: boolean = false;
  placement: Placement = "bottom-start";
  isOpen: boolean = false;
  trigger: HTMLElement | null = null;
  cancel: HTMLElement | null = null;
  content: HTMLElement | null = null;

  constructor() {
    super("ui-confirm-button");
  }

  attributeChangedCallback(name: string, oldValue: string, newValue: string) {
    super.attributeChangedCallback(name, oldValue, newValue);
    if (name === "placement") {
      this.content?.setAttribute("placement", newValue);
    }
  }

  connectedCallback() {
    const trigger = this.querySelector<HTMLElement>("ui-confirm-button-trigger");
    if (!trigger) {
      console.warn("Expected to find ui-confirm-button-trigger but none was found.");
      return;
    }
    this.trigger = trigger;

    this.trigger.addEventListener("click", this.onClick);

    const cancel = this.querySelector<HTMLElement>("button[data-confirm-action='cancel']");
    if (!cancel) {
      console.warn("Expected to find button[data-confirm-action='cancel'] but none was found.");
      return;
    }

    this.cancel = cancel;
    this.cancel.addEventListener("click", this.onClick);

    const content = this.querySelector<HTMLElement>("ui-confirm-button-content");
    if (!content) {
      console.warn("Expected to find ui-confirm-button-content but none was found.");
      return;
    }
    this.content = content;

    document.addEventListener("click", (e) => {
      if (this.isOpen && !this.contains(e.target as Node)) {
        this.setContentOpen(false);
      }
    });
  }

  setContentOpen(isOpen: boolean) {
    this.isOpen = isOpen;
    this.setContentPosition();
    this.setAttribute("open", this.isOpen.toString());
  }

  toggleContentOpen() {
    this.isOpen = !this.isOpen;
    this.setContentPosition();
    this.setAttribute("open", this.isOpen.toString());
  }

  setContentPosition() {
    if (!this.trigger || !this.content) return;

    if (window.innerWidth < 768) return;

    computePosition(this.trigger, this.content, {
      placement: this.placement,
      middleware: [flip(), offset(12), shift()],
    }).then(({ x, y }) => {
      Object.assign(this.content!.style, {
        left: `${x}px`,
        top: `${y}px`,
        right: "auto",
        bottom: "auto",
      });
    });
  }

  onClick = (_e: MouseEvent) => {
    this.toggleContentOpen();
  };

  static register() {
    customElements.define("ui-confirm-button", UIConfirmButton);
  }
}
