export const initUIInputs = (target?: HTMLElement) => {
  const element = target ?? document;
  const inputs = element.querySelectorAll<HTMLInputElement>(`[data-ui-input]`);

  inputs.forEach((input) => {
    const inputField = input.querySelector<HTMLInputElement>(`input`);
    if (!inputField) return;
    if (inputField.dataset.clearable) {
      const clearButton = input.querySelector<HTMLButtonElement>(`[data-clearable-trigger]`);
      if (!clearButton) return;
      clearButton.addEventListener("click", () => {
        inputField.value = "";
        inputField.dispatchEvent(new Event("change"));
      });
    }
  });
};
