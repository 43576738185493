export const initUIAccordion = (target?: HTMLElement) => {
  const element = target ?? document;
  const accordions = element.querySelectorAll<HTMLElement>(`[data-accordion]`);

  accordions.forEach((accordion) => {
    const isAutoClose = accordion.dataset.accordionAutoclose === "true";
    const triggers = accordion.querySelectorAll<HTMLElement>(`[data-accordion-trigger]`);
    triggers.forEach((trigger) => {
      const target = trigger.nextElementSibling as HTMLElement;

      if (typeof target.dataset.accordionContent === "undefined") {
        console.warn(`Expected to find accordion content element with attribute 'data-accordion-content' but found none.`);
        return;
      }

      if (trigger.dataset.open === "false") {
        target.style.maxHeight = "0";
      } else {
        // Accordion might be hidden and therefore has no height.
        // Clone the element, make it visible, measure the height, and remove it.
        const clone = target.cloneNode(true) as HTMLElement;
        clone.style.position = "absolute";
        clone.style.visibility = "hidden";
        document.body.appendChild(clone);
        const scrollHeight = clone.scrollHeight;
        clone.remove();

        target.style.maxHeight = scrollHeight + "px";
      }

      trigger.addEventListener("click", () => {
        trigger.dataset.open = trigger.dataset.open === "true" ? "false" : "true";
        if (trigger.dataset.open === "false") {
          target.style.maxHeight = "0";
        } else {
          target.style.maxHeight = target.scrollHeight + "px";
        }
        if (isAutoClose) {
          triggers.forEach((t) => {
            if (t === trigger) return;
            const c = t.nextElementSibling as HTMLElement;
            t.dataset.open = "false";
            c.style.maxHeight = "0";
          });
        }
      });
    });
  });
};

export const reSizeUIAccordions = (target?: HTMLElement) => {
  const element = target ?? document;
  const accordions = element.querySelectorAll<HTMLElement>(`[data-accordion]`);

  accordions.forEach((accordion) => {
    const triggers = accordion.querySelectorAll<HTMLElement>(`[data-accordion-trigger]`);
    triggers.forEach((trigger) => {
      const target = trigger.nextElementSibling as HTMLElement;
      if (trigger.dataset.open === "true") {
        target.style.maxHeight = target.scrollHeight + "px";
      } else {
        target.style.maxHeight = "0";
      }
    });
  });
};
