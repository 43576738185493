import { UIBaseComponent } from "./ui-base-component.ts";
import { computePosition, flip, offset, Placement, shift } from "@floating-ui/dom";

export class UIDropdown extends UIBaseComponent {
  static observedAttributes = ["hover", "placement"];
  hover: boolean = false;
  placement: Placement = "bottom-start";
  isOpen: boolean = false;
  trigger: HTMLElement | null = null;
  content: HTMLElement | null = null;

  constructor() {
    super("ui-dropdown");
  }

  attributeChangedCallback(name: string, oldValue: string, newValue: string) {
    super.attributeChangedCallback(name, oldValue, newValue);
    if (name === "hover") {
      this.hover = newValue === "true";
    }
    if (name === "placement") {
      this.content?.setAttribute("placement", newValue);
    }
  }

  connectedCallback() {
    const trigger = this.querySelector<HTMLElement>("ui-dropdown-trigger");
    if (!trigger) {
      console.warn("Expected to find ui-dropdown-trigger but none was found.");
      return;
    }
    this.trigger = trigger;

    this.trigger.addEventListener("click", this.onClick);

    const content = this.querySelector<HTMLElement>("ui-dropdown-content");
    if (!content) {
      console.warn("Expected to find ui-dropdown-content but none was found.");
      return;
    }
    this.content = content;

    if (this.hover && !window.matchMedia("(hover: none)").matches) {
      this.trigger.addEventListener("mouseenter", () => this.setContentOpen(true));
      this.addEventListener("mouseleave", () => this.setContentOpen(false));
    }

    document.addEventListener("click", (e) => {
      if (this.isOpen && !this.contains(e.target as Node)) {
        this.setContentOpen(false);
      }
    });
  }

  setContentOpen(isOpen: boolean) {
    this.isOpen = isOpen;
    this.setContentPosition();
    this.setAttribute("open", this.isOpen.toString());
  }

  toggleContentOpen() {
    this.isOpen = !this.isOpen;
    this.setContentPosition();
    this.setAttribute("open", this.isOpen.toString());
  }

  setContentPosition() {
    if (!this.trigger || !this.content) return;

    computePosition(this.trigger, this.content, {
      placement: this.placement,
      middleware: [flip(), offset(12), shift()],
    }).then(({ x, y }) => {
      Object.assign(this.content!.style, {
        left: `${x}px`,
        top: `${y}px`,
      });
    });
  }

  onClick = (_e: MouseEvent) => {
    this.toggleContentOpen();
  };

  static register() {
    customElements.define("ui-dropdown", UIDropdown);
  }
}
