export const initUITab = (target?: HTMLElement) => {
  const element = target ?? document;
  const triggers = element.querySelectorAll<HTMLElement>(`[data-tab-trigger]`);

  triggers.forEach((trigger) => {
    const target = trigger.dataset.tabTrigger;
    if (!target) return;

    const tab = document.querySelector<HTMLElement>(`[data-tab='${target}']`);
    if (!tab) {
      console.warn(`Expected to find tab element with attribute 'data-tab="${target}"' but found none.`);
    }

    trigger.addEventListener("click", () => {
      triggers.forEach((t) => {
        const isReplaceUrl = t.dataset.replaceUrl === "true";
        const triggerTarget = t.dataset.tabTrigger;
        const triggerTab = document.querySelector(`[data-tab='${triggerTarget}']`);
        if (t === trigger) {
          t.ariaSelected = "true";
          triggerTab.classList.remove("hidden");
          if (isReplaceUrl) {
            history.replaceState(null, "", `?tab=${triggerTarget}`);
          }
        } else {
          t.ariaSelected = "false";
          triggerTab.classList.add("hidden");
        }
      });
    });
  });
};
