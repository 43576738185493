const PAGINATION_ITEMS_NUM = 2;
export const initUIPagination = (target?: HTMLElement) => {
  const element = target ?? document;
  const triggers = element.querySelectorAll<HTMLElement>(`[data-page-trigger]`);
  triggers.forEach((trigger) => {
    const target = (trigger as HTMLElement).dataset.pageTrigger;
    if (!target) {
      console.warn(`Expected to find 'data-page-trigger' page reference value but found none.`);
      return;
    }

    const page = document.querySelector<HTMLElement>(`[data-page='${target}']`);
    if (!page && !["next", "prev"].includes(target)) {
      console.warn(`Expected to find a page with an attribute 'data-page="${target}"' but found none.`);
      return;
    }

    trigger.addEventListener("click", () => {
      let currentPage: HTMLElement | null = null;

      const allPages = document.querySelectorAll<HTMLElement>(`[data-page]`);
      allPages.forEach((p) => {
        if (p.dataset.open === "true") {
          currentPage = p;
        }
        p.dataset.open = "false";
      });

      let nextPage: HTMLElement | null = null;
      if (target === "next") {
        currentPage!.nextElementSibling?.setAttribute("data-open", "true");
        nextPage = (currentPage!.nextElementSibling as HTMLElement) ?? null;
      } else if (target === "prev") {
        currentPage!.previousElementSibling?.setAttribute("data-open", "true");
        nextPage = (currentPage!.previousElementSibling as HTMLElement) ?? null;
      } else {
        page!.dataset.open = "true";
        nextPage = page;
      }

      if (!nextPage) return;

      triggers.forEach((t) => {
        const dataPageTrigger = t.dataset.pageTrigger!;
        const targetPage = parseInt(nextPage!.dataset.page!);
        let triggerPage;

        if (dataPageTrigger === "prev" && targetPage == 1) {
          t.setAttribute("disabled", "true");
        } else if (dataPageTrigger === "next" && targetPage == allPages.length) {
          t.setAttribute("disabled", "true");
        } else {
          t.removeAttribute("disabled");
        }

        if (["next", "prev"].includes(dataPageTrigger)) {
          triggerPage = parseInt(nextPage!.dataset.page!);
        } else {
          triggerPage = parseInt(dataPageTrigger);
        }

        t.classList.add("hidden", "btn-solid", "btn-solid-brand", "btn-ghost", "btn-ghost-brand");
        t.dataset.active = "false";

        if (
          triggerPage >= targetPage - PAGINATION_ITEMS_NUM &&
          triggerPage != targetPage &&
          triggerPage <= targetPage + PAGINATION_ITEMS_NUM
        ) {
          t.classList.remove("hidden", "btn-solid", "btn-solid-brand");
        } else if (triggerPage === targetPage) {
          t.classList.remove("hidden", "btn-ghost", "btn-ghost-brand");
          t.dataset.active = "true";
        } else {
          t.classList.remove("btn-solid", "btn-solid-brand");
        }

        if (trigger.dataset.scroll === "true") {
          nextPage?.scrollIntoView({ behavior: "smooth" });
        }
      });
    });
  });
};
