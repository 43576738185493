// TODO: A better datepicker option?
import AirDatepicker from "air-datepicker";
import localeEn from "air-datepicker/locale/en";

export const initUIDatepickers = (target?: HTMLElement) => {
  const element = target ?? document;
  const dateInputs = element.querySelectorAll<HTMLInputElement>(`[data-ui-datepicker]`);
  dateInputs.forEach((dateInput) => {
    const valueInput = dateInput.querySelector<HTMLInputElement>("[data-ui-datepicker-value]");
    if (!valueInput) {
      console.warn(`Expected to find an input with attribute '[data-ui-datepicker-value]' to store datepicker resutl but found none.`);
      return;
    }
    const visibleInputs = dateInput.querySelectorAll<HTMLInputElement>(`input[type="text"]`);
    const selectedDate = dateInput.value;

    const airDatepickerOptions = {
      container: dateInput,
      locale: localeEn,
      selectedDates: [new Date(selectedDate ?? null)],
      dateFormat: "yyyy-MM-dd",
      // @ts-ignore
      onSelect: ({ date, formattedDate }) => {
        valueInput.value = formattedDate;
        valueInput.dispatchEvent(new Event("change"));
        visibleInputs.forEach((visibleInput) => {
          const datePart = visibleInput.dataset.datePart;
          const datePartValue = datePart === "day" ? date.getDate() : datePart === "month" ? date.getMonth() + 1 : date.getFullYear();
          visibleInput.value = datePartValue.toString().padStart(2, "0");
        });
      },
    };

    // @ts-ignore
    const airDatepicker = new AirDatepicker(`#${valueInput.id}`, airDatepickerOptions);

    // Show/hide datepicker on input click
    visibleInputs.forEach((visibleInput) => {
      visibleInput.addEventListener("click", () => {
        if (airDatepicker.visible) {
          airDatepicker.hide();
        } else {
          airDatepicker.show();
        }
      });
    });

    // Hide datepicker on outside click
    document.addEventListener("click", (event) => {
      const isClickInside = dateInput.contains(event.target as Node);
      if (!isClickInside && airDatepicker.visible) {
        airDatepicker?.hide();
      }
    });
  });
};
