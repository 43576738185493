export const getXhrResponseHeaders = (xhr: XMLHttpRequest): Promise<Record<string, string>> => {
  return new Promise((resolve) => {
    const getHeaders = () => {
      const headers = xhr.getAllResponseHeaders();
      return headers.split("\n").reduce(
        (acc, header) => {
          const [key, value] = header.split(": ");
          if (key && value) {
            acc[key] = value;
          }
          return acc;
        },
        {} as Record<string, string>
      );
    };

    if (xhr.readyState == 4) {
      const headers = getHeaders();
      resolve(headers);
    }
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        const headers = getHeaders();
        resolve(headers);
      }
    };
  });
};
