import Dropzone from "dropzone";
import htmx from "htmx.org";
import { initUIComponents } from "../index.ts";
import { getXhrResponseHeaders } from "../../utils";

// TODO: Use htmx file upload instead of dropzone?
export const initUIUploadBlock = (target?: HTMLElement) => {
  const element = target ?? document;
  const dropzones =
    target && target.matches(`.dz-dropzone`) ? [element as HTMLElement] : element.querySelectorAll<HTMLElement>(`.dz-dropzone`);
  dropzones.forEach((dropzone) => {
    const action = dropzone.dataset.dropzoneAction;
    if (!action) {
      throw new Error(`Expected to find a dropzone with an attribute 'data-action' but found none.`);
    }
    const dz = new Dropzone(dropzone, {
      previewTemplate: dropzone.querySelector<HTMLElement>(`.dz-preview`)?.innerHTML,
      url: action,
      maxFiles: 1,
      autoDiscover: false,
      uploadMultiple: false,
      addRemoveLinks: false,
      acceptedFiles: dropzone.dataset.dropzoneAcceptedFiles ?? "*/*",
      clickable: ["button[data-dz-target]"],
      hiddenInputContainer: dropzone.dataset.dropzoneInput ?? "body", // Dropzone's default is 'body'
      init: function () {
        // UI Upload Block can be initialized in file already uploaded state.
        // In this case, we need to simulate a completed file upload progress.
        const status = dropzone.dataset.status;
        if (status === "init-uploaded" && this.files.length === 0) {
          // TODO: Localization
          const mockFile = { name: "File previously already uploaded", size: 12345 };
          this.files.push(mockFile);
          this.emit("addedfile", mockFile);
          this.emit("processing", mockFile);
          this.emit("uploadprogress", mockFile, 100, 12345);
          this.emit("complete", mockFile);
        }
      },
      uploadprogress: function (file, progress, bytesSent) {
        if (file && file.previewElement) {
          file.previewElement.querySelector("[data-dz-uploadprogress]").style.width = progress + "%";
          file.previewElement.querySelector("[data-dz-progress-percentage]").textContent = progress + "%";
        }
      },
      success: async function (file, response) {
        // console.log(file, response);
        dropzone.dataset.status = "success";
        const resTarget = dropzone.dataset.dropzoneTarget;
        if (!resTarget) return;
        let target = document.querySelector<HTMLElement>(resTarget);
        if (!target) {
          throw new Error(`Expected to find a target element with the selector '${resTarget}' but found none.`);
        }
        target.outerHTML = response;
        target = document.querySelector<HTMLElement>(resTarget);

        const headers = await getXhrResponseHeaders(file.xhr);
        const hxData = JSON.parse(headers["hx-data"] ?? "{}");

        // Bind components and process htmx elements
        initUIComponents(target!);
        htmx.process(target!);

        const actionButton = dropzone.querySelector<HTMLButtonElement>(".dz-complete button[type='submit']");
        if (!actionButton) {
          console.warn(`Expected to find a button[type='submit'] element of file drop zone but found none.`);
          return;
        }

        if (!hxData.isScannable) {
          actionButton.setAttribute("disabled", "");
        } else {
          actionButton.removeAttribute("disabled");
        }
      },
      error: function (file, errorMessage) {
        dropzone.dataset.status = "error";
      },
    });

    // Add a listener to clear all previous files when a new file is added.
    // This is a workaround for cases where a file already is selected
    // and the user drops a new file on the dropzone.
    // https://github.com/dropzone/dropzone/issues/951#issuecomment-553933563
    dz.on("addedfile", function (file) {
      const maxFiles = dz.options.maxFiles ?? 0;
      for (let i = dz.files.length - maxFiles - 1; i >= 0; i--) {
        const f = dz.files[i];
        if (f.upload?.uuid !== file.upload?.uuid) {
          dz.removeFile(f);
        }
      }
    });
  });
};
