import { initUICollapse } from "./ui/ui-collapse";
import { initUIModals } from "./ui/ui-modal";
import { initUIUploadBlock } from "./ui/ui-upload-block";
import { initUIAccordion } from "./ui/ui-accordion";
import { initUITab } from "./ui/ui-tab";
import { initUIDatepickers } from "./ui/ui-datepicker";
import { initUIInputs } from "./ui/ui-input";
import { registerUICarousel } from "./ui/ui-carousel";
import { registerUICarouselTrigger } from "./ui/ui-carousel-trigger";
import { UIDropdown } from "./ui/ui-dropdown";
import { initUIPagination } from "./ui/ui-pagination";
import { UIConfirmButton } from "./ui/ui-confirm-button";

export const initUIComponents = (target?: HTMLElement) => {
  initUICollapse(target);
  initUIModals(target);
  initUIUploadBlock(target);
  initUIAccordion(target);
  initUITab(target);
  initUIDatepickers(target);
  initUIInputs(target);
  initUIPagination(target);
};

export const registerUIWebComponents = () => {
  registerUICarousel();
  registerUICarouselTrigger();
  UIDropdown.register();
  UIConfirmButton.register();
};
