const toggleNavigation = (navId: string) => {
  const nav = document.querySelector<HTMLElement>(`[data-nav="${navId}"]`);
  if (!nav) {
    throw new Error(`Expected to find navigation element with id '${navId}' but found none.`);
  }

  nav.dataset.open = nav.dataset.open === "true" ? "false" : "true";
};

const toggleMenu = (navId: string, menu: string) => {
  const menuContainer = document.querySelector<HTMLElement>(`[data-nav="${navId}"] [data-current-menu]`);
  if (!menuContainer) {
    throw new Error(`Expected to find a menu container element with 'data-current-menu' attribute but found none.`);
  }

  menuContainer.dataset.currentMenu = menu;
};

export const initNavigation = () => {
  const toggles = document.querySelectorAll<HTMLElement>(`[data-nav-trigger]`);

  toggles.forEach((toggle) =>
    toggle.addEventListener("click", () => {
      const navId = toggle.dataset.navTrigger;
      if (!navId) {
        throw new Error(`Expected to find 'data-nav-trigger' attribute but found none.`);
      }
      toggleNavigation(navId);
    })
  );

  const menuNavigationBtns = document.querySelectorAll<HTMLElement>(`[data-menu]`);
  menuNavigationBtns.forEach((btn) => {
    const nav = btn.closest<HTMLElement>(`[data-nav]`);
    const navId = nav?.dataset.nav;

    if (!nav || !navId) {
      throw new Error(`Expected to find a navigation element with 'data-nav' attribute but found none.`);
    }

    const navTrigger = document.querySelector<HTMLElement>(`[data-nav-trigger="${navId}"]`);
    if (!navTrigger) {
      throw new Error(`Expected to find a navigation trigger element with 'data-nav-trigger' attribute but found none.`);
    }

    btn.addEventListener("click", (e) => {
      if (window.getComputedStyle(navTrigger, null).display !== "none") {
        e.preventDefault();
      }

      const menu = btn.dataset.menu;
      if (!menu) {
        throw new Error(`Expected to find 'data-menu' attribute but found none.`);
      }
      toggleMenu(nav.dataset.nav!, menu);
    });
  });
};
