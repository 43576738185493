import { Toast } from "../types/ui/toast";

const toasts = () => {
  const _init = () => {
    window.pepchecker = window.pepchecker || {};

    if (window.pepchecker.toasts) {
      return window.pepchecker.toasts;
    }

    let prevId: number = 0;
    let toasts: HTMLElement[] = [];
    let toastsContainer = document.querySelector<HTMLElement>("#toasts-container");
    let toastTemplate = document.querySelector<HTMLElement>(".toast.template");

    if (!toastsContainer) {
      throw new Error("Expected to find a div with id 'toasts-container' but found none");
    }

    if (!toastTemplate) {
      throw new Error("Expected to find a toast template with selector '.toast.template' but found none");
    }

    document.addEventListener("htmx:afterSwap", () => {
      const toasts = document.querySelectorAll<HTMLElement>(".toast:not(.template)");
      toasts.forEach((t) => load(t));
    });

    document.addEventListener("addToast", (e) => {
      const { detail } = e as CustomEvent;
      add(detail);
    });

    const nextId = () => {
      prevId = (prevId + 1) % Number.MAX_SAFE_INTEGER;
      return prevId;
    };

    window.pepchecker.toasts = {
      toasts,
      toastsContainer,
      toastTemplate,
      nextId,
    };

    return window.pepchecker.toasts;
  };

  const store = _init();

  const _render = (toast: Toast, toastElement: HTMLElement) => {
    const { toasts, toastsContainer } = store;

    toastElement.classList.remove("hidden");
    toastElement.classList.remove("absolute");
    toastElement.id = "";
    toastElement.dataset.toastId = toast.id.toString();
    toastElement.dataset.type = toast.type.toLowerCase();
    toastElement.dataset.loading = toast.loading ? "true" : "false";
    toastElement.querySelector("[data-title]")!.textContent = toast.title;
    toastElement.querySelector("[data-message]")!.innerHTML = toast.message ?? "";

    const closeBtn = toastElement.querySelector<HTMLButtonElement>("[data-alert-action='close']");
    if (!toast.closable) {
      closeBtn?.remove();
    }

    toasts.push(toastElement);
    toastsContainer?.appendChild(toastElement);
    window.setTimeout(() => {
      toastElement.classList.add("toast-visible");
    }, 100);

    toasts.forEach((stateToast) => {
      if (stateToast.dataset.toastId === toast.id + "") return;
      const stateToastElement = document.querySelector<HTMLDivElement>(`[data-toast-id="${stateToast.id}"]`);
      const height = toastElement?.scrollHeight;
      stateToastElement?.animate([{ transform: `translateY(${height}px)` }, { transform: "translateY(0)" }], { duration: 200 });
    });

    if (toast.autoClose) {
      window.setTimeout(() => {
        toastElement.classList.remove("toast-visible");
        window.setTimeout(() => {
          remove(toast.id);
        }, 300);
      }, 10000);
    }

    if (toast.closable) {
      closeBtn?.addEventListener("click", () => {
        toastElement.classList.remove("toast-visible");
        window.setTimeout(() => {
          remove(toast.id);
        }, 300);
      });
    }
  };

  const load = (toast: HTMLElement) => {
    const { nextId } = store;
    const _toast: Partial<Toast> = {
      id: nextId(),
      title: toast.querySelector("[data-title]")?.textContent || "",
      message: toast.querySelector("[data-message]")?.textContent || "",
      type: toast.dataset.type as Toast["type"] | undefined,
      closable: toast.dataset.closable === "true",
      autoClose: true,
    };

    if (!_toast.title || !_toast.type) {
      throw new Error("Expected to find a toast element with data-title, data-type values but found none");
    }

    _render(_toast as Toast, toast);
  };

  const add = (toast: Omit<Toast, "id">) => {
    const { toastTemplate, nextId } = store;
    const id = nextId();
    const _toast = { closable: true, autoClose: true, loading: false, ...toast, id };
    const toastElement = toastTemplate?.cloneNode(true) as HTMLElement;
    _render(_toast, toastElement);
    return id;
  };

  const remove = (id: number) => {
    const { toasts } = store;
    const toastElement = toasts.find((t) => t.dataset.toastId === id + "");
    if (!toastElement) {
      console.warn(`Expected to find a toast with id '${id}' but found none`);
      return;
    }
    toastElement.remove();
    store.toasts.splice(store.toasts.indexOf(toastElement), 1);
  };

  const clearAll = () => {
    const { toasts } = store;
    toasts.forEach((t) => t.remove());
    toasts.splice(0, toasts.length);
  };

  return {
    loadToast: load,
    addToast: add,
    removeToast: remove,
    clearToasts: clearAll,
  };
};

export default toasts;

export const initToasts = () => {
  const { loadToast } = toasts();

  const t = document.querySelectorAll<HTMLElement>(".toast:not(.template)");
  t.forEach((t) => loadToast(t));
};
